$(window).on('load', function() {
    var now, lastDatePopupShowed;
    now = new Date();
  
    if (localStorage.getItem('lastDatePopupShowed') !== null) {
      lastDatePopupShowed = new Date(parseInt(localStorage.getItem('lastDatePopupShowed')));
    }
  
    if (((now - lastDatePopupShowed) >= (15 * 86400000)) || !lastDatePopupShowed) {
        setTimeout(function(){
            $('#js-cookies-popup').addClass('popup--open');
        }, 3000);
  
      localStorage.setItem('lastDatePopupShowed', now);
    }
});

(function ($) {

    $('#js-cookies-popup-close').on('click', function(e){
        $('#js-cookies-popup').removeClass('popup--open');
    })

})(jQuery);