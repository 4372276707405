(function ($) {

    // Add smooth scrolling to all links
    $(".js-smooth-scrolling").on('click', function(event) {

        if (this.hash !== "") {
            event.preventDefault();

            var hash = this.hash;

            $('html, body').animate({
                scrollTop: ($(hash).offset().top + 100)
            }, 500, function(){});
        }
    });

})(jQuery);
